<template>
  <div class="full-page">
    <template v-if="doctorInfo">
      <div class="header">
        <img src="@/assets/head-bg2.png">
      </div>
      <div class="doctors">
        <div class="doctor">
          <div class="card-left">
            <img v-if="doctorInfo.headUrl" :src="doctorInfo.headUrl | formatPicture">
            <img v-else src="@/assets/default-portrait.png">
          </div>
          <div class="card-right">
            <div class="title"><span>{{doctorInfo.name}}</span></div>
            <div class="doctor-tag"><span>{{doctorInfo.positionName}}</span></div>
            <div class="registration-fee"><span>¥{{doctorInfo.price | formatPrice}}</span></div>
          </div>
        </div>
      </div>

      <div class="doctors-specialty-column">
        <div class="doctors-specialty">
          <div class="title" @click="toDoctorDetail">
            <span>擅长</span>
            <img src="@/assets/arrow-right.png">
          </div>
          <div class="specialty">
            <div>{{doctorInfo.brief}}</div>
          </div>
        </div>
      </div>

      <div class="date-selection-column" v-if="arrangeRecords.length">
        <div class="column-bg">
          <div class="title">预约日期</div>
          <div class="date-selection-main">
            <div class="date-selection-main-left">
              <div class="bottom-position">
                <div class="time-label">
                  <div>上午</div>
                </div>
                <div class="time-label">
                  <div>下午</div>
                </div>
                <div class="time-label">
                  <div>晚上</div>
                </div>
              </div>
            </div>
            <div class="date-selection-main-right">
              <div class="day-selection-column">
                <div class="day-selection-item" v-for="(item, index) in arrangeRecords " :key="index"> 
                  <div class="text">{{item.visitTime | filterDate}}</div>
                  <div class="day">{{item.visitTime.split('-')[2]}}</div>
                </div>
              </div>
              <div class="date-appointment-column">
                <div class="time-status">
                  <div class="date-appointment" v-for="(item, index) in arrangeRecords " :key="index">
                    <div v-if="!item.arrangeRecords" style="background: #F5F5F5; color: #999999">休息</div>
                    <div v-else>
                      <div style="background: #00B6A4; color: #fff" v-if="item.morningAppointment" @click="handelAppointment(item, '上午')">预约</div>
                      <div style="background: #EAEAEA; color: #666666" v-else>已满</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="date-appointment-column">
                <div class="time-status">
                  <div class="date-appointment" v-for="(item, index) in arrangeRecords " :key="index">
                    <div v-if="!item.arrangeRecords" style="background: #F5F5F5; color: #999999">休息</div>
                    <div v-else>
                      <div v-if="item.afternoonAppointment" style="background: #00B6A4; color: #fff" @click="handelAppointment(item, '下午')">预约</div>
                      <div v-else style="background: #EAEAEA; color: #666666">已满</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="date-appointment-column">
                <div class="time-status">
                  <div class="date-appointment" v-for="(item, index) in arrangeRecords " :key="index">
                    <div v-if="!item.arrangeRecords" style="background: #F5F5F5; color: #999999">休息</div>
                    <div v-else>
                      <div v-if="item.nightAppointment" style="background: #00B6A4; color: #fff" @click="handelAppointment(item, '晚上')">预约</div>
                      <div v-else style="background: #EAEAEA; color: #666666">已满</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="remarks">注：最早可预约15天内的日期</div>
        </div>
      </div>

      <van-popup v-model="appointmentVisible" position="bottom" closeable :style="{ height: '67%' }">
        <div class="appointment-popup">
          <div class="header">
            <div class="appointment-date">
              <span>{{ appointInfo.visitTime }} </span>
              <span>{{ appointInfo.visitTime | filterDate }} </span>
              <span>{{ appointInfo.timeLabel }}</span>
            </div>
            <div class="appointment-department">{{'杏之林医疗门诊部（' + department + '）'}}</div>
            <div class="appointment-fee">
              <span>¥{{appointInfoPrice | formatPrice}}</span>
              <span v-if="hasPlus" style="font-size: .28rem"> (加号)</span>
            </div>
          </div>
          <div class="form-column">
            <div class="title">填写个人信息</div>
            <div class="form">
              <van-field v-model="query.name" label="姓 名" placeholder="请输入就诊人姓名" />
              <van-field v-model="query.age" label="年 龄" placeholder="请输入就诊人年龄" />
              <van-field v-model="query.sex" label="性 别" placeholder="请选择就诊人性别" />
              <van-field v-model="query.mobile" label="手机号码" placeholder="请输入就诊人联系手机" />
              
              <div class="submit-btn">
                <van-button color="#00B6A4" block :loading="btnLoading" loading-type="spinner" @click="confirmAppointment">确认预约</van-button>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        appointmentVisible: false,
        query: {
          name: '',
          age: '',
          sex: '',
          mobile: '',
          period: '',
          arrangeRecordsId: ''
        },
        btnLoading : false,

        doctorInfo: '',
        arrangeRecords: {},
        appointInfo: '',
        appointInfoPrice: '',
        hasPlus: ''
      }
    },

    computed: {
      ...mapState({
        department: (state) => state.user.department,
      }),
    },

    created() {
      this.getDoctorDetail()
    },

    methods: {
      confirmAppointment () {
        console.log(this.query)
        this.btnLoading = true
        
        this.$store.commit('setPayInfo', this.appointInfo)

        this.$request ({
          url: '/reservation/add',
          data: this.query
        }).then( res => {
          this.orderIdOrSN = res.result
          // console.log('预约新增返回成功，准备微信支付')
          this.toPay()
          this.btnLoading = false
        }).catch( () => {
          this.btnLoading = false
        })
      },

      getDoctorDetail () {
        this.$request ({
          url: '/doctors/detail/' + this.$route.params.id,
          data: {}
        }).then( res => {
          this.doctorInfo = res.result

          this.getArrangeRecords()
        })
      },

      getArrangeRecords () {
        this.$request ({
          url: '/visitingArrange/getArrangeRecords',
          data: {id: this.doctorInfo.id}
        }).then( res => {
          this.arrangeRecords = res.result

          this.arrangeRecords.map( item => {
            item.morningAppointment = item.arrangeRecords.actualMorningNumber < (item.arrangeRecords.morningNumber + item.arrangeRecords.plusMorningNumber)
            item.afternoonAppointment = item.arrangeRecords.actualAfternoonNumber < (item.arrangeRecords.afternoonNumber + item.arrangeRecords.plusAfternoonNumber)
            item.nightAppointment = item.arrangeRecords.actualNightNumber < (item.arrangeRecords.nightNumber + item.arrangeRecords.plusNightNumber)

            return item
          })

        })
      },

      handelAppointment ( item, timeLabel ) {
        this.appointmentVisible = true
        this.appointInfo = item.arrangeRecords
        this.appointInfo.timeLabel = timeLabel

        console.log(item)

        this.hasPlus = false

        // 判断是否存在加号
        if(this.appointInfo.hasPlus) {
          if(timeLabel == '上午') {
            // 判断当前时间段的普通号是否已经被预约完，如果是，则显示加号的价格
            if(this.appointInfo.morningNumber <= this.appointInfo.actualMorningNumber) {
              this.appointInfoPrice = this.appointInfo.plusPrice
              this.hasPlus = true
            } else {
              this.appointInfoPrice = this.appointInfo.price
            }
          }

          if(timeLabel == '下午') {
            if(this.appointInfo.afternoonNumber <= this.appointInfo.actualAfternoonNumber) {
              this.appointInfoPrice = this.appointInfo.plusPrice
              this.hasPlus = true
            } else {
              this.appointInfoPrice = this.appointInfo.price
            }
          }

          if(timeLabel == '晚上') {
            if(this.appointInfo.nightNumber <= this.appointInfo.actualNightNumber) {
              this.appointInfoPrice = this.appointInfo.plusPrice
              this.hasPlus = true
            } else {
              this.appointInfoPrice = this.appointInfo.price
            }
          }
        } else {
          this.appointInfoPrice = this.appointInfo.price
        }

        // 预约参数赋值
        this.query.period = timeLabel == '上午'? 'MORNING' : (timeLabel == '下午'? 'AFTERNOON' : 'NIGHT')
        this.query.arrangeRecordsId = this.appointInfo.id

      },

      toDoctorDetail () {
         this.$router.push({path: '/doctor-detail', name: 'doctor-detail', params: {id: this.doctorInfo.id}})
      },

      toPay () {
        this.$router.push({path: '/pay-page', name: 'pay-page', params: {orderIdOrSN: this.orderIdOrSN}})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .full-page {
    .header {
      img {
        width: 100%;
      }
    }

    .doctors {
      padding: 0 .24rem;
      margin-top: -1.34rem;
      position: relative;
      .doctor {
        background: #fff;
        border-radius: .08rem;
        display: flex;
        align-items: center;
        padding: .24rem;
        margin-top: .24rem;
        &:nth-child(1) {
          margin-top: 0;
        }
        .card-left {
          margin-right: .24rem;
          border-radius: .08rem;
          overflow: hidden;
          img {
            width: 1.6rem;
            height: 1.6rem;
            object-fit: cover;
            display: block;
          }
        }
        .card-right {
          position: relative;
          flex: 1;
          .title {
            font-size: .32rem;
            margin-bottom: .08rem;
          }
          .doctor-tag {
            width: max-content;
            padding: .04rem;
            border-radius: .06rem;
            color: #00B6A4;
            border: 1px solid #00B6A4;
            font-size: .22rem;
          }
          .registration-fee {
            font-size: .36rem;
            color: #E02021;
            margin-top: 0.27rem;
          }
        }
      }
    }

    .doctors-specialty-column {
      padding: 0 .24rem;
      margin-top: .24rem;
      .doctors-specialty {
        background: #fff;
        border-radius: .16rem;
        .title {
          padding: .24rem;
          border-bottom: 1px solid #EEEEEE;
          font-size: .3rem;
          font-weight: bold;
          img {
            width: .4rem;
            float: right;
          }
        }
        .specialty {
          padding: .24rem;
          font-size: .26rem;
          >div {
            line-height: .4rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }
    }

    .appointment-popup {
      .header {
        padding: .24rem;
        border: 1px solid #EEEEEE;
        .appointment-date {
          font-size: .36rem;
          font-weight: bold;
        }
        .appointment-department {
          font-size: .26rem;
          color: #333;
          margin-top: .12rem;
        }
        .appointment-fee {
          color: #E02021;
          font-size: .36rem;
          margin-top: .24rem;
        }
      }

      .form-column {
        padding: .24rem;
        font-size: .3rem;
        .form {
          margin-top: .4rem;

          .submit-btn {
            margin-top: .8rem;
            border-radius: .08rem;
            overflow: hidden;
          }

          /deep/ .van-cell {
            padding: .2rem 0;
          }
          /deep/ .van-cell::after {
            left: 0;
          }
          /deep/ .van-field__label {
            width: 4.2em;
            margin-right: .4rem;
            text-align-last: justify;
            color: #333333;
          }
        }
      }
    }

    .date-selection-column {
      padding: .24rem;
      margin-top: .24rem;
      border-radius: .16rem;
      padding: 0 .24rem;
      .column-bg {
        background: #fff;
        padding: .24rem;
        border-radius: .16rem;

        .remarks {
          font-size: .26rem;
          color: #AAAAAA;
          padding: .24rem 0;
        }
      }
      .title {
        font-size: .3rem;
        font-weight: bold;
        margin-bottom: .25rem;
      }
      .date-selection-main {
        padding-top: .24rem;
        background: #F5F5F5;
        display: flex;
        .date-selection-main-left {
          position: relative;
          width: .8rem;
          padding: 0 .12rem;
          margin-right: .04rem;
          .bottom-position {
            position: absolute;
            left: 0;
            bottom: 0;
          }
          .time-label {
            display:inline-block;
            background: #E6FFFC;
            padding: 0 .12rem;
            height: 1.35rem;
            margin: .1rem 0;

            >div {
              text-align: center;
              position: relative;
              top:50%;
              transform:translateY(-50%);
            }
          }
        }
        .date-selection-main-right {
          white-space: nowrap;
          overflow-x: auto;
          .day-selection-column {
            margin-bottom: .1rem;
            .day-selection-item {
              display: inline-block;
              text-align: center;
              margin: 0 .02rem;
              width: .85rem;
              .text {
                font-size: .22rem;
              }
              .day {
                font-size: .3rem;
                font-weight: bold;
                margin-top: .1rem;
              }
            }
          }

          .date-appointment-column {
            display: flex;
            .time-status {
              display: flex;
              align-items: center;

              .date-appointment {
                display: inline-block;
                margin: .1rem .02rem;
                text-align: center;
                width: .85rem;
                height: 1.35rem;
                line-height: 1.35rem;
              }
            }
          }
        }
      }
    }
  }
  
</style>